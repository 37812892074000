import React from "react";

import './Button.css'

export default class Button extends React.Component {

	handleClick(e) {
		console.log(e)
	}

	render() {
		return (
			<button className={"fancy-button " + (this.props.className || '')}
				onClick={this.props.onClick || this.handleClick.bind(this)}>
				<span className="fancy-button-content">
					{this.props.value || this.props.children}
				</span>
			</button>
		)
	}
}