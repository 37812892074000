import React from 'react';
import { useHistory } from 'react-router-dom';
import "./Login.css"
import Button from "./Button";

function Login(props) {
	const history = useHistory();

	function onSubmitForm(e) {
		e.preventDefault();

		props.onLogin();

		history.push('/dashboard');
	}

		return (
			<div className="Login box">
				<div className="form-container">
					<h1>Login</h1>
					<form onSubmit={onSubmitForm}>
						<div className="form-body">
							<label htmlFor="username">Username</label>
							<input id="username" type="text" placeholder="Username..."/>
							<label htmlFor="password">Password</label>
							<input id="password" type="password" placeholder="Passwort..."/>
						</div>
						<Button className="fancy-button-full">
							Login
						</Button>
						{/*<input type="submit" value="Login" className="btn btn-full"/>*/}
					</form>
				</div>
			</div>
		);
}

export default Login;
