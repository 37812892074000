import React from "react";

import PropagateLoader from "react-spinners/ClipLoader";

export function mockLoad(timeout, context) {
	if(context.state) {
		context.setState({loading: true});
	} else {
		context.setState({loading: true});
	}

	return setTimeout(function() {
		context.setState({loading: false});
	}, timeout)
}

export default class Loader extends React.Component {
	render() {
		if(this.props.active) {
			return(
				<div className="box" style={{textAlign: 'center'}}>
					<PropagateLoader
						size={150}
						loading={true}
					/>
				</div>)
		}

		return this.props.children;
	}
}