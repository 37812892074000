import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'html5-device-mockups/dist/device-mockups.css'
import Wave from "./Wave";

const params = new URLSearchParams(window.location.search);

if(params.has('no-mobile')) {
	ReactDOM.render(
		<React.StrictMode>
			<App mobile={false}/>
		</React.StrictMode>,
		document.getElementById('root')
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<div className="device-wrapper" id="mobile-emulated">
				<div className="device" data-device="iPhone7" data-orientation="portrait" data-color="black">
					<div className="screen">
						<App mobile={true}/>
					</div>
					<div className="button">
					</div>
				</div>
			</div>

			<Wave />


		</React.StrictMode>
		, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
