import React from 'react';
import logo from './logo.svg';
import './App.css';

import CategorySelector from './CategorySelector'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

import Login from './Login.js';
import Registration from './Registration.js';
import MessagePreview from "./MessagePreview";
import Reply from "./Reply";
import Dashboard from "./Dashboard";
import About from "./About";

class App extends React.Component{
	constructor(props) {
		super(props);

		this.state = {login: false};
	}
	componentDidMount() {
		if(!this.props.mobile) {
			document.body.classList.add('body-desktop-mode')
		}
	}

	handleLogin() {
		this.setState({login: 'Anna'})
	}

	render() {
		return (
			<div className="App">
				<Router>
					<header className="app-header">
						<div className="center">
							<div className="left">
								<Link to="/">
									<img src="./logo.svg" alt="Logo" className="logo"/>
								</Link>
							</div>
							{this.state.login ?
								<div className="userinfo">
									<Link to="/dashboard">
										<span className="username">{this.state.login}</span>
										<img src="./63.jpg" alt="" className="userimg"/>
									</Link>
								</div>
								:
								<div className="userinfo">
									<Link to="/login">
										<img src="./iconmonstr-user-6.png" alt="" className="userimg anonymous"/>
									</Link>
								</div>
							}
						</div>

					</header>
					<main id="main-content">
						<Switch>
							<Route path="/about">
								<h1>About</h1>
							</Route>
							<Route path="/login">
								<Login onLogin={this.handleLogin.bind(this)} />
							</Route>
							<Route path="/new">
								<CategorySelector/>
							</Route>
							<Route path="/message-preview">
								<MessagePreview></MessagePreview>
							</Route>
							<Route path="/registration">
								<Registration/>
							</Route>
							<Route path="/reply">
								<Reply/>
							</Route>
							<Route path="/dashboard">
								<Dashboard/>
							</Route>
							<Route path="/">
								<About/>
							</Route>
						</Switch>
					</main>
				</Router>
			</div>
		);
	}
}

export default App;
