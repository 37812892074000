import React from 'react';

import './CategorySelector.css'
import {
	Redirect
} from "react-router-dom";

class CategorySelector extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			redirectTo: null
		}
	}

	handleClick(item) {
		console.log(item);

		this.setState({redirectTo: 'yes'});
	}

	render() {

		if(this.state.redirectTo) {
			return (<Redirect to="/message-preview" />)
		}

		let categories = [
			{
				title: 'Autos',
				image: 'assets/iconmonstr-car-2.svg'
			},
			{
				title: 'Badminton',
				image: 'assets/iconmonstr-badminton-2.svg'
			},{
				title: 'Bildung',
				image: 'assets/iconmonstr-school-22.svg'
			},{
				title: 'Wissenschaft',
				image: 'assets/iconmonstr-school-18.svg'
			},{
				title: 'Billiard',
				image: 'assets/iconmonstr-snooker-1.svg'
			},{
				title: 'Volleyball',
				image: 'assets/iconmonstr-volleyball-2.svg'
			},{
				title: 'Musik',
				image: 'assets/iconmonstr-party-15.svg'
			},{
				title: 'Reisen',
				image: 'assets/iconmonstr-airport-15.svg'
			},{
				title: 'Filme',
				image: 'assets/iconmonstr-video-9.svg'
			},{
				title: 'Geschichte',
				image: 'assets/iconmonstr-time-17.svg'
			},
		];

		return (
			<div className="category-selector box">
				<header>
					<h2>Nachricht in dieser Kategorie beginnen</h2>
				</header>
				<main>
				{categories.map(x =>
					<article key={x.title} className="category-item" onClick={this.handleClick.bind(this)}>
						<img src={x.image} alt=""/>
						<span className="title">{x.title}</span>
					</article>
				)}
				</main>
			</div>
		)
	}
}

export default CategorySelector;
