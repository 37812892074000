import React from "react";

import './WaveBorder.css'

export default class WaveBorder extends React.Component {
	render() {
		return (
			<div className="wave-border-container">
			</div>
		)
	}
}