import React from "react";

import {Redirect} from 'react-router-dom';
import Loader, {mockLoad} from "./Loader";

import './MessagePreview.css'
import Button from "./Button";
import WaveBorder from "./WaveBorder";

class MessagePreview extends React.Component {
	constructor(props) {
		super(props);

		this.state = {redirectTo: null}
	}

	componentDidMount() {
		mockLoad(500, this);
	}

	handleClick(e) {
		e.preventDefault();

		this.setState({redirectTo: 'yes'})
	}

	render() {
		if(this.state.redirectTo) {
			return <Redirect to="/reply" />
		}



		return (
			<Loader active={this.state.loading}>
				<div className="message-preview box">

					<h1>Nachricht</h1>

					<header className="message-header">
						<span className="label">Von: </span>Waltraud<br/>
						<span className="label">am </span>16.09.2020
					</header>

					<WaveBorder></WaveBorder>

					<main>
						<p>Lieber neuer Freund!</p>
						<p>In den letzten Jahren hatte ich Schwierigkeiten, neue Leute kennenzulernen. Wegen meinem
							schlechten Knie komme ich nur noch selten aus dem Haus. Darum suche ich nach einem
							Briefreund!<br/>
							Mein Name ist Waltraud und ich bin 78 Jahre alt. Ich lebe schon seit 42 Jahren in Wien.
							Davor habe ich schon fast überall gewohnt: Prag, Oslo, Ägypten, Taiwan, Peru, ...</p>
						<p>Leider sind die Tage der Reiserei schon hinter mir.</p>
						<p>Es würde mich sehr freuen, wenn ich dir meine Geschichtenteilen kann und wir gemeinsam über
							unsere Erfahrungen reden können!<br/>
							Welche Orte durftest du schon besuchen?</p>
						<p>Ich hoffe, bald von dir zu hören!</p>
						<p>
							Alles Liebe,<br/>
							Waltraud
						</p>

					</main>

					<footer>
						<Button className="btn-big" onClick={this.handleClick.bind(this)}>
							Jetzt antworten
						</Button>
					</footer>
				</div>
			</Loader>
		)
	}
}

export default MessagePreview;
