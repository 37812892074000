import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import "./Reply.css"
import TextareaAutosize from 'react-textarea-autosize';

function Reply() {
	const history = useHistory();
	const [replied, setReplied] = useState('')
	const [text, setText] = useState('')

	const DEMO_TEXT = `Hallo Waltraud,

mein Name ist Anna und ich bin 26 Jahre alt. Zurzeit arbeite ich in einemArchitekturstudio in Wien, wo es zurzeit ziemlich viel zu tun gibt. Es ist komisch, einen normalen Alltag mit Arbeit zu haben, das bin ich gar nicht mehr gewohnt.

Ich war nämlich die letzten 8 Monate auf Weltreise! 

Leider hat mir Corona einen Strich durch die Rechnung gemacht und ich musste meine Reise abbrechen. Während meiner Reise habe ich so viele neue Erfahrungen und Eindrücke gewonnen, ich möchte eigentlich über nichts anderes mehr reden. Nur meine Freunde können es schon gar nicht mehr hören. Deswegen kommt deine Nachricht zur richtigen Zeit!

Meine Reise habe ich in Prag begonnen. Als Architektin war ich sehr interessiert an der Stadt, all die gotischen und barrocken Gebäude haben es mir ziemlich angetan. Warum bist du damals nach Prag gereist? Und was ist dir am meisten in Erinnerung geblieben? 

Alles Liebe,
Anna`;

	useEffect(() => {
		updateScroll();
	})

	function updateScroll() {
		const objDiv = document.getElementById('messages-container');
		if(objDiv) {
			objDiv.scrollTop = objDiv.scrollHeight;
		}
	}

	function onSubmitForm(e) {
		e.preventDefault();

		history.push('/');
	}

	function handleClick(e) {
		e.preventDefault();
		// setReplied(text);
		setReplied(text.split('\n\n').map((text, i) => (
			<p key={"p" + i}>{text.split('\n').map((x, idx) => {
				return (<span key={"span" + idx}>{x}<br/></span>)
			})}</p>
		)));
		// console.log(replied)
		setText('');
	}

	function onAttachFile(e) {
		e.preventDefault();

		document.getElementById("chatinput-file-selector").click();
	}

	function handleChange(event) {
		// setText(event.target.value);
		// console.log(event.target.value);
		if(event.target.value.length === 1) {
			setText(DEMO_TEXT);
		} else {
			setText(event.target.value);
		}
	}

	return (
			<div className="Reply">
				<div className="messages-container" id="messages-container">
					{/*div.message*30>p{lipsum slfkl sdfjkldfs ksdfjkldsf l jklsdf jkldsf jkldsf jkldsf}*/}
					<div className="message">
						<article className="message">
							<header></header>
							<main>
								<p>Lieber neuer Freund!</p>
								<p>In den letzten Jahren hatte ich Schwierigkeiten, neue Leute kennenzulernen. Wegen meinem
									schlechten Knie komme ich nur noch selten aus dem Haus. Darum suche ich nach einem
									Briefreund!<br/>
									Mein Name ist Waltraud und ich bin 78 Jahre alt. Ich lebe schon seit 42 Jahren in Wien.
									Davor habe ich schon fast überall gewohnt: Prag, Oslo, Ägypten, Taiwan, Peru, ...</p>
								<p>Leider sind die Tage der Reiserei schon hinter mir.</p>
								<p>Es würde mich sehr freuen, wenn ich dir meine Geschichtenteilen kann und wir gemeinsam über
									unsere Erfahrungen reden können!<br/>
									Welche Orte durftest du schon besuchen?</p>
								<p>Ich hoffe, bald von dir zu hören!</p>
								<p>
									Alles Liebe,<br/>
									Waltraud
								</p>
							</main>
						</article>
						<article className={"message from-me " + (replied ? '' : 'hidden')}>
							<header>Me</header>
							<main>
								{/*<p>Hallo Waltraud,</p>
								<p>mein Name ist Anna und ich bin 26 Jahre alt. Zurzeit arbeite ich in einem
									Architekturstudio in Wien, wo es zurzeit ziemlich viel zu tun gibt.
									Es ist komisch, einen normalen Alltag mit Arbeit zu haben, das bin ich gar nicht
									mehr gewohnt.</p>
								<p>Ich war nämlich die letzten 8 Monate auf Weltreise!</p>
								<p>Leider hat mir Corona einen Strich durch die Rechnung gemacht und ich musste meine
									Reise abbrechen
									Während meiner Reise habe ich so viele neue Erfahrungen und Eindrücke gewonnen, ich
									möchte eigentlich über nichts anderes mehr reden. Nur meine Freunde können es schon
									gar nicht mehr hören…<br/>
									Deswegen kommt deine Nachricht zur richtigen Zeit!</p>
								<p>Meine Reise habe ich in Prag begonnen. Als Architektin war ich sehr interessiert an
									der Stadt, all die gotischen und barrocken Gebäude haben es mir ziemlich angetan.
									Warum bist du damals nach Prag gereist? Und was ist dir am meisten in Erinnerung
									geblieben?</p>

								<p>Alles Liebe,<br/>
								Anna</p>*/}

								{replied ? replied : ''}
							</main>
						</article>
					</div>
				</div>

				<div className="chat-input">
					<form onSubmit={onSubmitForm}>
						<div className="message-input-container">
							{/* todo autosize https://flaviocopes.com/urlsearchparams/, http://www.jacklmoore.com/autosize/#faq-min-max */}
							<div className="textarea-container">
								<TextareaAutosize
									maxRows="4"
									id="message-input"
									onHeightChange={updateScroll.bind(this)}
									onChange={handleChange.bind(this)}
									value={text}
									onClick={handleChange}
									placeholder="Deine Antwort..."
								/>
								<span className="attach">
									<img src="./assets/iconmonstr-paperclip-1.svg" alt="" onClick={onAttachFile.bind(this)} style={{display: (text.length) ? 'none' : 'block'}}/>
									<input type="file" id="chatinput-file-selector"/>
								</span>
							</div>
							<div className="btn-container">
								<span className="centered-button">
									<img src="./assets/iconmonstr-paper-plane-2.svg" alt="" onClick={handleClick.bind(this)}/>
								</span>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
}

export default Reply;
