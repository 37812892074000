import React from "react";
import {Link} from 'react-router-dom'

import {withRouter} from 'react-router-dom';

import './Dashboard.css';

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
	}

	handleClick(e) {
		e.preventDefault();

		this.props.history.push('/reply')
	}

	handleNew(e) {
		this.props.history.push('/new');
	}

	render() {
		return (
			<div className="dashboard">
				<header className="box">
					<h1>Hallo, Anna!</h1>
					<p>Du hast derzeit keine neuen Nachrichten. Wenn du möchtest, kannst du neue <Link to="/new">Leute
						kennenlernen</Link>.</p>
				</header>
				<main>

					<div className="box message message-read" onClick={this.handleClick.bind(this)}>
						<main>
							<span className="status">
								<img src="./assets/iconmonstr-email-8.svg" alt=""/>
							</span>
							<span className="text">
								Deine Unterhaltung mit Waltraud
							</span>
							<span className="action">
								<img src="./assets/iconmonstr-arrow-25.svg" alt=""/>
							</span>
						</main>
					</div>

					<div className="box message message-unread" onClick={this.handleClick.bind(this)}>
						<main>
							<span className="status">
								<img src="./assets/iconmonstr-email-4.svg" alt=""/>
							</span>
							<span className="text">
								Deine Unterhaltung mit Karl
							</span>
							<span className="action">
								<img src="./assets/iconmonstr-arrow-25.svg" alt=""/>
							</span>
						</main>
					</div>

					<div className="box message message-dummy message-unread" onClick={this.handleNew.bind(this)}>
						<main>
							<span className="status">
								<img src="./assets/iconmonstr-email-4.svg" alt=""/>
							</span>
							<span className="text">
								Entdecke spannende Persönlichkeiten...
							</span>
							<span className="action">
								<img src="./assets/iconmonstr-binoculars-8.svg" alt=""/>
							</span>
						</main>
					</div>

				</main>
			</div>
		)
	}
}

export default withRouter(Dashboard);
