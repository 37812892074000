import React from 'react';
import './About.css'

function About() {
	return (
		<div id="about">
			<header id="header">
				<img src="./logo.svg" alt="Logo" width="500px" height="auto"/>
				<h1>Flaschenpost <br/>—<br/> gestern, heute & morgen! </h1>
			</header>

			<main>
				<img src="./Scheme.png" alt="Schematic" width="1000px" height="auto"/>

				<h2>What is it? </h2>

				<p><em>Flaschenpost</em>, German for "Message in a bottle", is a service that connects snail mail to
					modern technologies such as Instant Messaging. Flaschenpost brings a novel communication concept
					connecting the elderly with the younger generations, using technologies that each generation is
					comfortable with.</p>

				<h2>How does it work? </h2>

				<p> Elderly people in nursing homes and in their own homes can write letters to their peers and younger
					people by
					simply mailing them to our address. We then scan those letters and digitalize them, sending it to
					younger
					people via the Flaschenpost App. Responses are printed out and mailed to the senior, all while
					providing privacy for both parties.</p>
				<img src="./Young_old.png" alt="Schematic" width="1000px" height="auto"/>

				<h2>Why should we care? </h2>

				<p>
					Social isolation and loneliness are some of the most common risk factors for developing chronic
					medical conditions, such as dementia, depression and anxiety and cardiovascular diseases. Elderly
					individuals are at a particularly high risk, as they are often living alone, suffering loss of loved
					ones and are not familiar with modern technologies that could connect them to other people. With
					Flaschenbrief, they can reconnect to their communities and improve their quality of life. Our vision
					is to eliminate social isolation altogether because we believe
					that anyone should have someone to talk to.
				</p>
			</main>
			<div className="container">
				<p>If you want to know more, please <a href="mailto:daniel.laxar@dhps.lbg.ac.at">get
					in touch</a>.</p>
			</div>
		</div>
	)
}

export default About;
