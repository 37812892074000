import React from 'react';
import { useHistory } from 'react-router-dom';
import "./Registration.css"


function Registration() {
	const history = useHistory();


	function onSubmitForm(e) {
		e.preventDefault();

		history.push('/');
	}

		return (
			<div className="Registration">
				<div className="form-container">
					<h1>Registration</h1>
					<form onSubmit={onSubmitForm}>
						<div className="form-body">
							<label htmlFor="email">E-Mail</label><input id="username" type="email"/>
							<label htmlFor="username">Username</label><input id="username" type="text"/>
							<label htmlFor="password">Passwort</label><input id="password" type="password"/>
							<label htmlFor="confirm_password">Passwort wiederholen</label><input id="confirm_password" type="password"/>
						</div>
						<input type="submit" value="Jetzt anmelden"/>
					</form>
				</div>
			</div>
		);
}

export default Registration;
